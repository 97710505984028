var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-space-between",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4",staticStyle:{"flex-grow":"1"}},[_vm._v(" "+_vm._s(_vm.heading)+" "),(_vm.ready && !_vm.loading && _vm.rapporter.length)?_c('span',[_vm._v(" ( "+_vm._s(_vm.rapporter.length)+")")]):_vm._e()]),(_vm.canCreate)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.create}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Generer ")],1):_vm._e()],1)],1),_c('v-row',[_c('v-toolbar',{staticClass:"mt-6",attrs:{"color":"grey lighten-4","elevation":"4"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,4294619505)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Med alle")]),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":_vm.exportExcel}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Eksporter")])],1)],1)],1)],1)],1),(_vm.hasRole('ANSATT'))?_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.typer,"item-text":"navn","item-value":"id","label":"Tjeneste","hide-details":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.arValg,"item-text":"navn","item-value":"id","label":"År","hide-details":""},model:{value:(_vm.ar),callback:function ($$v) {_vm.ar=$$v},expression:"ar"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.manedValg,"item-text":"navn","item-value":"id","label":"Måned","hide-details":""},model:{value:(_vm.maned),callback:function ($$v) {_vm.maned=$$v},expression:"maned"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.levertValg,"item-text":"navn","item-value":"id","label":"Levert","hide-details":""},model:{value:(_vm.levert),callback:function ($$v) {_vm.levert=$$v},expression:"levert"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rapporter,"items-per-page":-1,"item-class":"v-data-table-item--link","custom-sort":_vm.customSort,"must-sort":"","hide-default-footer":"","no-data-text":"Ingen rapporter"},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type.navn)+" ")]}},{key:"item.ar",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format")(item.dato,'YYYY'))+" ")]}},{key:"item.maned",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format")(item.dato,'MMMM'))+" ")]}},{key:"item.frivillig",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.frivillig.arkivert ? '(Frivillig slettet)' : item.frivillig.fulltNavn)+" ")]}},{key:"item.antallKoblinger",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.antallKoblinger || '')+" ")]}},{key:"item.antallGanger",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.antallGanger || '')+" ")]}},{key:"item.antallTimer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.antallTimer || '')+" ")]}},{key:"item.kommentar",fn:function(ref){
var item = ref.item;
return [(item.kommentar)?[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information")])]:_vm._e()]}},{key:"item.opprettet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format")(item.opprettet,'DD.MM.YYYY'))+" ")]}},{key:"item.levert",fn:function(ref){
var item = ref.item;
return [(item.levert)?[_vm._v(" "+_vm._s(_vm._f("format")(item.levert,'DD.MM.YYYY')))]:_vm._e()]}}],null,false,1000034708)})],1)],1)],1),_c('report-dialog',{ref:"reportDialog",attrs:{"rapporter":_vm.sortertListe},on:{"closed":_vm.closedDialog}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }